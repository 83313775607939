$(function() {
  // 初回読み込み時の処理
  setModelOptions()

	// 車名が変更されたら発動
	$('select[id="q_maker_maker_eq"]').change(function() {
		setModelOptions()
	})
})

  // APIからモデルを取得し、セレクトボックスのオプション要素を設定
  function setModelOptions(){
		// 選択されているメーカー名を取得
    var is_local_sale = false
    var country       = null
    var maker_id      = $("#q_maker_maker_eq option:selected").data("children-path");
    var path_ary      = window.location.pathname.split("/").filter(n => n)
    if (path_ary[0] === "local_sales"){
      is_local_sale = true
      country       = path_ary[1]
    }

    $('select#q_car_model_model_eq option').remove();
    $.ajax({
      type: "GET",
      url:  "/api/model/" + maker_id,
      data: {maker_id: maker_id, local_sales: is_local_sale, country: country}
    }).done(function(data){
      // 成功の場合の処理
      $('#q_car_model_model_eq').append($('<option>').html('Select Model').val(""));

      // selectedの設定
      var arg  = new Object;
      url = location.search.substring(1).split('&')
      for(i=0; url[i]; i++) {
        var j = decodeURIComponent(url[i].replace(/\+/g,' ').split('='))
        var k = j.split(',')
        arg[k[0]] = k[1]
      }
      var car_model_model_eq = arg["q[car_model_model_eq]"];
      var url = decodeURIComponent(location.href)
      var elm = $('<a>', { href:url } )[0]
      var path = elm.pathname.split('/')
      $.each(data, function(index, value) {
        if(car_model_model_eq == value[1] || decodeURIComponent(path[3]) == value[1]){
          $('#q_car_model_model_eq').append($('<option>').html(value[0]).val(value[1]).prop("selected", true));
        }else{
          $('#q_car_model_model_eq').append($('<option>').html(value[0]).val(value[1]));
        }
      })
    }).fail(function(){
      // エラーの場合処理
      console.log('error')
    });
  }

